import {
  Button,
  Chip,
  IconButton,
  CircularProgress,
  Snackbar,
  Tooltip,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { useState } from "react";
import { useRefresh } from "react-admin";
import { createDocument } from "../services/documentsService";

const ContractGenerateButton = ({ record = {}, iconOnly }) => {
  const refresh = useRefresh();
  const [isGenerating, setIsGenerating] = useState(false);
  const [generationError, setGenerationError] = useState({
    open: false,
    message: "",
  });

  const handleGenerateClick = () => {
    setIsGenerating(true);
    createDocument(record.id)
      .then(refresh)
      .catch(() =>
        setGenerationError({
          open: true,
          message: "Hubo un error generando el PDF",
        })
      )
      .finally(() => setIsGenerating(false));
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setGenerationError({ open: false });
  };

  const validStates = ["Pagado", "Entregado"];
  const enabled = validStates.includes(record.status);

  if (record.pdf_generation_mode !== "AUTOMATIC")
    return <Chip label="Generación manual" />;

  if (isGenerating) {
    return (
      <Chip icon={<CircularProgress size="1rem" />} label="Generando PDF" />
    );
  }

  const button = (
    <>
      <Snackbar
        open={generationError.open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert elevation={6} variant="filled" severity="error">
          {generationError.message}
        </Alert>
      </Snackbar>
      {iconOnly ? (
        <IconButton
          color="primary"
          aria-label="Generar"
          onClick={handleGenerateClick}
          disabled={!enabled}
        >
          <PictureAsPdfIcon />
        </IconButton>
      ) : (
        <Button
          color="primary"
          size="small"
          onClick={handleGenerateClick}
          startIcon={<PictureAsPdfIcon />}
          label="Generar"
          disabled={!enabled}
        >
          Generar
        </Button>
      )}
    </>
  );

  return enabled ? (
    button
  ) : (
    <Tooltip title="El documento no ha sido pagado aún">
      <span>{button}</span>
    </Tooltip>
  );
};

export default ContractGenerateButton;
