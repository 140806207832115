import * as React from "react";
import {
  Edit,
  SimpleForm,
  Toolbar,
  SaveButton,
  TextField,
  EmailField,
  SelectInput,
  TopToolbar,
  ListButton,
  ShowButton,
} from "react-admin";

import AssignmentIcon from "@material-ui/icons/Assignment";
import contractStatusChoices from "./contractStatusChoices";
import CustomChipField from "../fields/customChipField";
import CustomDateField from "../fields/customDateField";

const ContractEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const ContractEditActions = ({ basePath, data, resource }) => {
  return (
    <TopToolbar>
      <ListButton basePath={basePath} label="Listado" />
      <ShowButton basePath={basePath} record={data} icon={<AssignmentIcon />} />
    </TopToolbar>
  );
};

export const ContractEdit = (props) => {
  return (
    <Edit {...props} actions={<ContractEditActions />}>
      <SimpleForm toolbar={<ContractEditToolbar />}>
        <SelectInput source="status" choices={contractStatusChoices} />

        <TextField source="id" />
        <EmailField source="recipient_email" label="Email" />
        <TextField source="country" label="País" />
        <CustomChipField source="status" label="Estado" />
        <TextField source="description" label="Tipo" />
        <CustomDateField source="created_at" label="Fecha de creación" />
      </SimpleForm>
    </Edit>
  );
};
