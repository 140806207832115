import { Button, IconButton } from "@material-ui/core";
import { useState } from "react";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import React from "react";

const ButtonWithConfirmation = ({
  iconOnly,
  title,
  icon,
  dialogDescription,
  onOkClick,
  disabled,
}) => {
  const [open, setOpen] = useState(false);

  const handleOkClick = () => {
    onOkClick();
    setOpen(false);
  };

  return (
    <>
      {iconOnly ? (
        <IconButton
          color="primary"
          aria-label={title}
          onClick={() => setOpen(true)}
          target="_blank"
          disabled={disabled}
        >
          {icon}
        </IconButton>
      ) : (
        <Button
          color="primary"
          size="small"
          onClick={() => setOpen(true)}
          target="_blank"
          startIcon={icon}
          label={title}
          disabled={disabled}
        >
          {title}
        </Button>
      )}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        ref={React.createRef()}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogDescription}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleOkClick} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ButtonWithConfirmation;
