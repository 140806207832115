// in src/MyAppBar.js
import * as React from "react";
import { InputBase, Select, MenuItem } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { AppBar } from "react-admin";
import { getCountry, saveCountry } from "../utils/country";

const CustomInput = withStyles((theme) => ({
  input: {
    backgroundColor: "transparent",
    border: "none",
    color: "#FFFFFF",
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: "1",
    [theme.breakpoints.down("sm")]: {
      visibility: "hidden",
    },
  },
  selectIcon: {
    fill: "#FFFFFF",
  },
}));

const MyAppBar = (props) => {
  const classes = useStyles();
  const currentCountry = getCountry();

  const [country, setCountry] = React.useState(currentCountry);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setCountry(newValue);
    saveCountry(newValue);
  };

  return (
    <AppBar {...props} color="primary">
      <div className={classes.title}>OnLegal admin</div>
      <Select
        inputProps={{
          classes: {
            icon: classes.selectIcon,
          },
        }}
        input={<CustomInput />}
        value={country}
        onChange={handleChange}
      >
        <MenuItem value="AR">Argentina</MenuItem>
        <MenuItem value="PE">Perú</MenuItem>
      </Select>
    </AppBar>
  );
};

export default MyAppBar;
