import { DateTime } from 'luxon';
import { FunctionField } from "react-admin";
import { getCountry, getTimeZone } from '../utils/country';

const CustomDateField = (props) => {
  const country = getCountry();
  return (
    <FunctionField {...props} render={record => { 
        const dt = DateTime.fromISO(record.created_at, {zone: 'utc'}).setZone(getTimeZone(country)).toLocaleString(DateTime.DATETIME_SHORT);
        return `${dt}`}} 
    />

  );
};

export default CustomDateField;
