import * as React from "react";
import { Admin, Resource } from "react-admin";
import { ContractList } from "./contract/contractList";
import { ContractEdit } from "./contract/contractEdit";
import { ContractShow } from "./contract/contractShow";
import theme from "./theme";
import DescriptionIcon from "@material-ui/icons/Description";
import spanishMessages from "@blackbox-vision/ra-language-spanish";
import polyglotI18nProvider from "ra-i18n-polyglot";
import springBootdataProvider from "./dataProvider";
import authProvider from "./authProvider";
import Dashboard from "./Dashboard";
import Layout from "./Layout";

const dataProvider = springBootdataProvider(
  process.env.REACT_APP_API_BACKOFFICE_BASE_URL
);

// Case you have only those messsages
const messages = {
  es: spanishMessages,
};
const i18nProvider = polyglotI18nProvider(() => messages["es"]);

const App = () => (
  <Admin
    dataProvider={dataProvider}
    authProvider={authProvider}
    i18nProvider={i18nProvider}
    dashboard={Dashboard}
    theme={theme}
    layout={Layout}
  >
    <Resource
      name="contracts"
      list={ContractList}
      edit={ContractEdit}
      show={ContractShow}
      icon={DescriptionIcon}
    />
  </Admin>
);

export default App;
