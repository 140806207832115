const authProvider = {
  login: ({ username, password }) => {
    const request = new Request(
      `${process.env.REACT_APP_API_BASE_URL}/sessions`,
      {
        method: "POST",
        body: JSON.stringify({ email: username, password }),
        headers: new Headers({ "Content-Type": "application/json" }),
        credentials: "include",
      }
    );
    return fetch(request).then((response) => {
      if (response.ok) {
        return Promise.resolve();
      }
      return Promise.reject("ra.auth.sign_in_error");
    });
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  checkAuth: () => {
    const request = new Request(`${process.env.REACT_APP_API_BASE_URL}/me`, {
      credentials: "include",
    });
    return fetch(request).then((response) => {
      if (response.ok) {
        return Promise.resolve();
      }
      return Promise.reject("ra.auth.auth_check_error");
    });
  },
  logout: () => {
    const request = new Request(
      `${process.env.REACT_APP_API_BASE_URL}/sessions`,
      {
        method: "DELETE",
        credentials: "include",
      }
    );
    return fetch(request).then(() => {
      return Promise.resolve();
    });
  },
  getIdentity: () => {
    const request = new Request(`${process.env.REACT_APP_API_BASE_URL}/me`, {
      credentials: "include",
    });
    return fetch(request)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject("ra.auth.sign_in_error");
        }
      })
      .then((me) => {
        return Promise.resolve({
          id: me.email || "",
          fullName: `${me.first_name || ""} ${me.last_name || ""}`,
        });
      });
  },
  getPermissions: () => {
    return Promise.resolve([]);
  },
};

export default authProvider;
