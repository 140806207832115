import { getCountry } from "../utils/country";

const getContractInfo = () => {
  const country = getCountry();
  return fetch(
    `${process.env.REACT_APP_API_BASE_URL}/contracts_info?country=${country}`,
    { credentials: "include" }
  ).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error();
    }
  });
};

const getContractInfoTypes = async () => {
  const response = await getContractInfo();

  const contractsInfoMapping = {};
  response.forEach((contractInfo) => {
    contractsInfoMapping[contractInfo.type] = contractInfo.title;
  });

  return Object.keys(contractsInfoMapping).map((key) => ({
    id: key,
    name: contractsInfoMapping[key],
  }));
};

export { getContractInfo, getContractInfoTypes };
