import * as React from "react";
import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Link as MuiLink,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Title } from "react-admin";
import { Link } from "react-router-dom";
import { getStats } from "./services/statsService";
import { getCountry } from "./utils/country";

const useStyles = makeStyles({
  statBoxWrapper: {
    boxShadow: "0px 0px 3px #e5e5e5",
    padding: "1rem",
    textAlign: "center",
  },
  statBox: {
    display: "inline-block",
    padding: "2rem",

    "&:hover": {
      background: "rgba(0,0,0,0.05)",
      textDecoration: "none",
    },
  },
  loadingContainer: {
    textAlign: "center",
  },
});

const Dashboard = () => {
  const classes = useStyles();
  const [stats, setStats] = React.useState({
    loading: true,
    data: [],
    error: false,
  });

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const stats = await getStats();
        setStats({ loading: false, data: stats, error: false });
      } catch {
        setStats({ loading: false, data: [], error: true });
      }
    };
    fetchData();
  }, []);

  return (
    <Card>
      <Title title="OnLegal Admin" />
      <Card>
        <CardContent>
          <Typography variant="h5" component="h1">
            OnLegal Admin
          </Typography>
        </CardContent>
      </Card>
      {stats.loading ? (
        <Card>
          <CardContent className={classes.loadingContainer}>
            <CircularProgress />
            <Typography variant="h6">Cargando...</Typography>
          </CardContent>
        </Card>
      ) : (
        <Card>
          <CardContent>
            {stats.error && (
              <Typography variant="body1" component="h2">
                Error cargando las estadísticas
              </Typography>
            )}
            <Grid container spacing={3} direction="row" alignItems="flex-start">
              {stats.data.map((contractType) => (
                <Grid item xs key={contractType.type}>
                  <div className={classes.statBoxWrapper}>
                    <Typography variant="h6">
                      {contractType.description}
                    </Typography>
                    <MuiLink
                      className={classes.statBox}
                      color="primary"
                      component={Link}
                      to={{
                        pathname: "/contracts",
                        search: `filter=${JSON.stringify({
                          country: getCountry(),
                          status: "PAYED",
                          contract_type: contractType.type,
                        })}&order=ASC&sort=created_at`,
                      }}
                    >
                      <Typography variant="h2">{contractType.count}</Typography>
                      Pendientes
                    </MuiLink>
                  </div>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      )}
    </Card>
  );
};

export default Dashboard;
