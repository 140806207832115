export const getCountry = () =>
  localStorage.getItem("onlegal_admin_country") || "AR";

export const saveCountry = (country) => {
  localStorage.setItem("onlegal_admin_country", country);
  window.location = "/";
};

export const getTimeZone = (country) => {
  const timezones = {
    AR: "America/Argentina/Buenos_Aires",
    PE: "America/Lima",
  };

  return timezones[country] || timezones["AR"];
};
