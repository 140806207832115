import * as React from "react";
import { useMediaQuery } from "@material-ui/core";
import {
  Show,
  SimpleShowLayout,
  Datagrid,
  TextField,
  EmailField,
  ArrayField,
  TopToolbar,
  EditButton,
  ListButton,
} from "react-admin";
import CustomChipField from "../fields/customChipField";
import ContractDownloadButton from "./contractDownloadButton";
import ContractGenerateButton from "./contractGenerateButton";
import ContractSendButton from "./contractSendButton";
import CustomDateField from "../fields/customDateField";

const ContractShowActions = ({ basePath, data, resource }) => {
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  return (
    <TopToolbar>
      <ListButton basePath={basePath} label="Listado" />
      <EditButton basePath={basePath} record={data} />
      <ContractGenerateButton record={data} iconOnly={isXSmall} />
      <ContractDownloadButton record={data} iconOnly={isXSmall} />
      <ContractSendButton record={data} iconOnly={isXSmall} />
    </TopToolbar>
  );
};

export const ContractShow = (props) => {
  return (
    <Show {...props} actions={<ContractShowActions />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <EmailField source="recipient_email" label="Email" />
        <TextField source="recipient_phone" label="Teléfono" />
        <TextField source="country" label="País" />
        <CustomChipField source="status" label="Estado" />
        <TextField source="description" label="Tipo" />
        <TextField
          source="comments"
          label="Observaciones"
          emptyText="Sin observaciones"
        />
        <CustomDateField source="created_at" label="Fecha de creación" />
        <ArrayField source="sections">
          <Datagrid>
            <TextField source="title" label="Sección" />
            <ArrayField source="data">
              <Datagrid>
                <TextField source="label" label="Campo" />
                <TextField source="value" label="Valor" />
              </Datagrid>
            </ArrayField>
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};
