import * as React from "react";
import { useMediaQuery } from "@material-ui/core";
import ContractDownloadButton from "./contractDownloadButton";
import ContractGenerateButton from "./contractGenerateButton";
import ContractSendButton from "./contractSendButton";

import {
  List,
  SimpleList,
  Datagrid,
  TextField,
  ShowButton,
  Filter,
  SelectInput,
  DateInput,
  TextInput,
} from "react-admin";
import contractStatusChoices from "./contractStatusChoices";
import CustomChipField from "../fields/customChipField";
import { getCountry } from "../utils/country";
import { getContractInfoTypes } from "../services/contractInfoService";
import CustomDateField from "../fields/customDateField";

const ContractFilter = (props) => {
  const [contractTypes, setContractTypes] = React.useState([]);

  React.useEffect(() => {
    getContractInfoTypes()
      .then((types) => setContractTypes(types))
      .catch((e) => console.log(e));
  }, []);

  return (
    <Filter {...props}>
      <SelectInput
        source="status"
        emptyText="Todos"
        choices={contractStatusChoices}
        alwaysOn
      />
      <SelectInput
        source="contract_type"
        emptyText="Todos"
        choices={contractTypes}
        alwaysOn
      />
      <DateInput source="from" alwaysOn />
      <TextInput label="Email" source="recipient_email" type="email" alwaysOn />
    </Filter>
  );
};

export const ContractList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const country = getCountry();

  return (
    <List
      {...props}
      filters={<ContractFilter />}
      sort={{ field: "created_at", order: "ASC" }}
      filterDefaultValues={{
        country: country,
        status: "PAYED",
      }}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.recipient_email}
          secondaryText={(record) => record.description}
          linkType="show"
        />
      ) : (
        <Datagrid>
          <TextField source="recipient_email" label="Email" />
          <TextField source="recipient_phone" label="Teléfono" />
          <TextField source="description" sortable={false} label="Tipo" />
          <CustomChipField source="status" label="Estado" />
          <CustomDateField source="created_at" label="Creado" />
          <ShowButton label="Ver" />
          <ContractGenerateButton />
          <ContractDownloadButton />
          <ContractSendButton />
        </Datagrid>
      )}
    </List>
  );
};
