const getDocument = (documentId) => {
  return fetch(
    `${process.env.REACT_APP_API_BACKOFFICE_BASE_URL}/contracts/${documentId}/pdf`,
    { credentials: "include" }
  ).then((response) => {
    if (response.ok) {
      return response.blob();
    } else {
      throw new Error();
    }
  });
};

const createDocument = (documentId) => {
  return fetch(
    `${process.env.REACT_APP_API_BACKOFFICE_BASE_URL}/contracts/${documentId}/pdf`,
    { method: "POST", credentials: "include" }
  ).then((response) => {
    if (!response.ok) {
      throw new Error();
    }
    return response;
  });
};

const sendDocument = (documentId) => {
  return fetch(
    `${process.env.REACT_APP_API_BACKOFFICE_BASE_URL}/contracts/${documentId}/pdf/send`,
    { method: "POST", credentials: "include" }
  ).then((response) => {
    if (!response.ok) {
      throw new Error();
    }
    return response;
  });
};

export { getDocument, createDocument, sendDocument };
