import { makeStyles } from "@material-ui/core/styles";
import { ChipField } from "react-admin";

const useStyles = makeStyles({
  completado: { backgroundColor: "#8cc3fd" },
  pagado: { backgroundColor: "#ffac44" },
  "en-preparación": { backgroundColor: "#f1dd4c" },
  entregado: { backgroundColor: "#58e47c" },
});

const CustomChipField = (props) => {
  const classes = useStyles();
  return (
    <ChipField
      className={
        classes[props.record[props.source].replace(/ /g, "-").toLowerCase()]
      }
      {...props}
    />
  );
};

export default CustomChipField;
