import { Chip, CircularProgress, Snackbar, Tooltip } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import ButtonWithConfirmation from "../components/buttonWithConfirmaton";
import EmailIcon from "@material-ui/icons/Email";
import { useState } from "react";
import { useRefresh } from "react-admin";
import { sendDocument } from "../services/documentsService";

const ContractSendButton = ({ record = {}, iconOnly }) => {
  const refresh = useRefresh();
  const [isSending, setIsSending] = useState(false);
  const [sendError, setSendError] = useState({
    open: false,
    message: "",
  });

  const handleSendClick = () => {
    setIsSending(true);
    sendDocument(record.id)
      .then(refresh)
      .catch(() =>
        setSendError({
          open: true,
          message: "Hubo un error enviando el PDF",
        })
      )
      .finally(() => setIsSending(false));
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSendError({ open: false });
  };

  if (record.pdf_generation_mode !== "AUTOMATIC") {
    return null;
  }

  if (isSending) {
    return (
      <Chip icon={<CircularProgress size="1rem" />} label="Enviando e-mail" />
    );
  }

  const button = (
    <>
      <Snackbar
        open={sendError.open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert elevation={6} variant="filled" severity="error">
          {sendError.message}
        </Alert>
      </Snackbar>
      <ButtonWithConfirmation
        title="Enviar"
        icon={<EmailIcon />}
        iconOnly={iconOnly}
        dialogDescription={`Estás seguro de que quieres enviar el ${record.description} a ${record.recipient_email}?`}
        onOkClick={handleSendClick}
        disabled={!record.has_generated_pdf}
      />
    </>
  );

  return record.has_generated_pdf ? (
    button
  ) : (
    <Tooltip title="El PDF no está generado aún">
      <span>{button}</span>
    </Tooltip>
  );
};

export default ContractSendButton;
