import { Button, IconButton, Tooltip } from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import { getDocument } from "../services/documentsService";

const ContractDownloadButton = ({ record = {}, iconOnly }) => {
  const handleViewClick = () => {
    getDocument(record.id).then((blob) => {
      var newBlob = new Blob([blob], { type: "application/pdf" });
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = `${record.id}.pdf`;
      link.click();
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);
    });
  };

  if (record.pdf_generation_mode !== "AUTOMATIC") {
    return null;
  }

  const button = iconOnly ? (
    <IconButton
      color="primary"
      aria-label="Ver"
      onClick={handleViewClick}
      target="_blank"
      disabled={!record.has_generated_pdf}
    >
      <DownloadIcon />
    </IconButton>
  ) : (
    <Button
      color="primary"
      size="small"
      onClick={handleViewClick}
      target="_blank"
      startIcon={<DownloadIcon />}
      label="Descargar"
      disabled={!record.has_generated_pdf}
    >
      Descargar
    </Button>
  );

  return record.has_generated_pdf ? (
    button
  ) : (
    <Tooltip title="El PDF no está generado aún">
      <span>{button}</span>
    </Tooltip>
  );
};

export default ContractDownloadButton;
