import { getCountry } from "../utils/country";

const getStats = () => {
  const country = getCountry();
  return fetch(
    `${process.env.REACT_APP_API_BACKOFFICE_BASE_URL}/contracts/statistics?country=${country}&status=PAYED`,
    { credentials: "include" }
  ).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error();
    }
  });
};

export { getStats };
